<section>
  <napa-dialog-header *ngIf="!customHeader(); else projectedPopupHeader" (closeDialog)="close()"></napa-dialog-header>

  <napa-dialog-body *ngIf="!customBody(); else projectedPopupBody"></napa-dialog-body>
</section>

<napa-dialog-footer
  *ngIf="!customFooter(); else projectedPopupFooter"
  [isLoading]="isLoading"
  (primaryBtnClicked)="handlePrimaryBtnClick()"
  (secondaryBtnClicked)="handleSecondaryBtnClick()"
></napa-dialog-footer>

<ng-template #projectedPopupHeader>
  <ng-content select="napa-dialog-header"></ng-content>
</ng-template>

<ng-template #projectedPopupBody>
  <ng-content select="napa-dialog-body"></ng-content>
</ng-template>

<ng-template #projectedPopupFooter>
  <ng-content select="napa-dialog-footer"></ng-content>
</ng-template>
