<div class="dialog-footer" *ngIf="!customContent; else customFooterContent">
  <div class="dialog-footer__actions">
    <wlcm-button *ngIf="config.hasSecondaryBtn" type="borderless" (clicked)="secondaryBtnClicked.emit()">
      {{ config.secondaryBtnText }}
    </wlcm-button>

    <wlcm-button *ngIf="config.hasPrimaryBtn" (clicked)="primaryBtnClicked.emit()" [isLoading]="isLoading">
      {{ config.primaryBtnText }}
    </wlcm-button>
  </div>
</div>

<ng-template #customFooterContent>
  <ng-content select="[napaCustomDialogContent]"></ng-content>
</ng-template>
