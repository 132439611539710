import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  Signal,
  contentChild,
} from '@angular/core';
import { NapaDialogConfig, NapaDialogCloseEvent } from '../../models/dialog.models';
import { DialogFooterComponent } from '../dialog-footer/dialog-footer.component';
import { DialogBodyComponent } from '../dialog-body/dialog-body.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';

@Component({
  selector: 'napa-generic-dialog',
  host: { class: 'napa-generic-dialog' },
  templateUrl: './generic-dialog.component.html',
  styleUrl: './generic-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericDialogComponent {
  @Input() isLoading: boolean = false;

  @Output() primaryBtnClicked: EventEmitter<boolean> = new EventEmitter();

  @Output() secondaryBtnClicked: EventEmitter<boolean> = new EventEmitter();

  protected customHeader: Signal<DialogHeaderComponent | undefined> = contentChild(DialogHeaderComponent);

  protected customBody: Signal<DialogBodyComponent | undefined> = contentChild(DialogBodyComponent);

  protected customFooter: Signal<DialogFooterComponent | undefined> = contentChild(DialogFooterComponent);

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: NapaDialogConfig,
    private readonly dialogRef: MatDialogRef<GenericDialogComponent, NapaDialogCloseEvent>
  ) {}

  close(): void {
    this.dialogRef.close({ closeBtnClicked: true });
  }

  handlePrimaryBtnClick(): void {
    this.primaryBtnClicked.emit();

    if (this.data.closeOnButtonClick) {
      this.dialogRef.close({ primaryBtnClicked: true });
    }
  }

  handleSecondaryBtnClick(): void {
    this.secondaryBtnClicked.emit();

    if (this.data.closeOnButtonClick) {
      this.dialogRef.close({ secondaryBtnClicked: true });
    }
  }
}
