<svg
  width="24px"
  height="24px"
  viewBox="0 0 24 24"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g id="A-4.0-Settings" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Edit-Location" transform="translate(-552, -92)">
      <g id="Group-3" transform="translate(64, 60)">
        <g id="Group" transform="translate(488, 32)">
          <rect id="Rectangle" fill="#DBEBF3" x="0" y="0" width="24" height="24" rx="8"></rect>
          <g id="icon/close" transform="translate(4, 4)">
            <rect id="Rectangle" x="0" y="0" width="16" height="16"></rect>
            <g
              id="Group-2"
              transform="translate(3.0503, 3.0503)"
              stroke="#1A7DB8"
              stroke-linecap="round"
              stroke-width="2"
            >
              <line
                x1="4.94974747"
                y1="-1.55025253"
                x2="4.94974747"
                y2="11.4497475"
                id="Path-4"
                transform="translate(4.9497, 4.9497) rotate(-315) translate(-4.9497, -4.9497)"
              ></line>
              <line
                x1="11.4497475"
                y1="4.94974747"
                x2="-1.55025253"
                y2="4.94974747"
                id="Path-4"
                transform="translate(4.9497, 4.9497) rotate(-315) translate(-4.9497, -4.9497)"
              ></line>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
