import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomDialogContentDirective } from '../../directives/custom-dialog-content.directive';
import { NapaDialogConfig } from '../../models/dialog.models';

@Component({
  selector: 'napa-dialog-footer',
  templateUrl: './dialog-footer.component.html',
  styleUrl: './dialog-footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogFooterComponent {
  @Input() isLoading: boolean = false;

  @Output() primaryBtnClicked: EventEmitter<boolean> = new EventEmitter();

  @Output() secondaryBtnClicked: EventEmitter<boolean> = new EventEmitter();

  @ContentChild(CustomDialogContentDirective) protected customContent?: CustomDialogContentDirective;

  constructor(@Inject(MAT_DIALOG_DATA) public readonly config: NapaDialogConfig) {}
}
