import { MatDialogConfig } from '@angular/material/dialog';
import { NapaDialogConfig } from '../models/dialog.models';

export const DEFAULT_DIALOG_PANEL_CONFIG: MatDialogConfig = {
  width: '90%',
  maxWidth: '540px',
  maxHeight: '80vh',
  panelClass: 'napa-dialog-panel',
};

export const SUCCESS_CONFIG: Partial<NapaDialogConfig> = {
  title: 'Success',
  body: 'Operation completed successfully',
  hasPrimaryBtn: true,
  hasSecondaryBtn: false,
};

export const ERROR_CONFIG: Partial<NapaDialogConfig> = {
  title: 'Error',
  body: 'Something went wrong',
  hasPrimaryBtn: true,
  primaryBtnText: 'Got it!',
  hasSecondaryBtn: false,
};
