<svg
  width="16px"
  height="16px"
  viewBox="0 0 16 16"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g id="icon/date-grey" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <rect id="Rectangle" x="0" y="0" width="16" height="16"></rect>
    <path
      d="M11,0 C11.5522847,0 12,0.44771525 12,1 L14,1 C15.1045695,1 16,1.8954305 16,3 L16,14 C16,15.1045695 15.1045695,16 14,16 L2,16 C0.8954305,16 0,15.1045695 0,14 L0,3 C0,1.8954305 0.8954305,1 2,1 L4,1 C4,0.44771525 4.44771525,0 5,0 C5.55228475,0 6,0.44771525 6,1 L10,1 C10,0.44771525 10.4477153,0 11,0 Z M14,7 L2,7 L2,14 L14,14 L14,7 Z M4,3 L2,3 L2,5 L14,5 L14,3 L12,3 C12,3.55228475 11.5522847,4 11,4 C10.4477153,4 10,3.55228475 10,3 L6,3 C6,3.55228475 5.55228475,4 5,4 C4.44771525,4 4,3.55228475 4,3 Z"
      id="Combined-Shape"
      fill="currentColor"
      fill-rule="nonzero"
    ></path>
    <rect id="Rectangle" fill="currentColor" x="9" y="9" width="3" height="3" rx="1"></rect>
  </g>
</svg>
