import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { InlineSVGModule } from 'ng-inline-svg-2';

import { CustomDialogContentDirective } from './directives/custom-dialog-content.directive';

import { WlcmButtonModule } from '@wlcm/angular/button';
import { DialogBodyComponent } from './components/dialog-body/dialog-body.component';
import { DialogFooterComponent } from './components/dialog-footer/dialog-footer.component';
import { DialogHeaderComponent } from './components/dialog-header/dialog-header.component';
import { GenericDialogComponent } from './components/generic-dialog/generic-dialog.component';
import { SquareCloseIconComponent } from '@shared/components/icons/square-close-icon/square-close-icon.component';
import { DialogApi } from './services/dialog.api';

const publicComponents: Type<unknown>[] = [
  GenericDialogComponent,
  DialogHeaderComponent,
  DialogFooterComponent,
  DialogBodyComponent,
];

@NgModule({
  declarations: [...publicComponents, CustomDialogContentDirective],
  imports: [CommonModule, MatDialogModule, InlineSVGModule, WlcmButtonModule, SquareCloseIconComponent],
  exports: [...publicComponents, CustomDialogContentDirective],
  providers: [DialogApi],
})
export class DialogModule {}
