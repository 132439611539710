import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Inject, Output } from '@angular/core';
import { CustomDialogContentDirective } from '../../directives/custom-dialog-content.directive';
import { NapaDialogConfig } from '../../models/dialog.models';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'napa-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrl: './dialog-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogHeaderComponent {
  @Output() closeDialog: EventEmitter<void> = new EventEmitter();

  @ContentChild(CustomDialogContentDirective) protected customContent?: CustomDialogContentDirective;

  constructor(@Inject(MAT_DIALOG_DATA) public readonly config: NapaDialogConfig) {}
}
