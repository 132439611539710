import { Injectable } from '@angular/core';
import { DEFAULT_DIALOG_PANEL_CONFIG, ERROR_CONFIG, SUCCESS_CONFIG } from '../constants/dialog.constants';
import { GenericDialogComponent } from '../components/generic-dialog/generic-dialog.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { NapaDialogConfig } from '../models/dialog.models';
import { ComponentType } from '@angular/cdk/portal';

@Injectable()
export class DialogApi {
  constructor(private readonly dialog: MatDialog) {}

  openSuccessPopup(data: NapaDialogConfig = {}, config: MatDialogConfig = {}): MatDialogRef<GenericDialogComponent> {
    return this.dialog.open(GenericDialogComponent, {
      ...this.mergeConfigs(config),
      data: { ...SUCCESS_CONFIG, ...data },
    });
  }

  openErrorPopup(data: NapaDialogConfig = {}, config: MatDialogConfig = {}): MatDialogRef<GenericDialogComponent> {
    return this.dialog.open(GenericDialogComponent, {
      ...this.mergeConfigs(config),
      data: { ...ERROR_CONFIG, ...data },
    });
  }

  openGenericPopup(data: NapaDialogConfig, config: MatDialogConfig = {}): MatDialogRef<GenericDialogComponent> {
    return this.dialog.open(GenericDialogComponent, { ...this.mergeConfigs(config), data });
  }

  openCustomPopup<T, D>(
    type: ComponentType<T>,
    data: NapaDialogConfig<D> = {},
    config: MatDialogConfig = {}
  ): MatDialogRef<T> {
    return this.dialog.open(type, { data, ...this.mergeConfigs(config) });
  }

  private mergeConfigs(config: MatDialogConfig): MatDialogConfig {
    return { ...DEFAULT_DIALOG_PANEL_CONFIG, ...config };
  }
}
